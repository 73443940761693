<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <EmailVerify :email="email" :def="def" :redirect="redirect" :tid="tid" />
      <GoogleVerify :def="def" :redirect="redirect" :tid="tid" />
      <SmsVerify :mobile="mobile" :def="def" :redirect="redirect" :tid="tid" />
    </div>
  </div>
</template>

<script>
import { bindInfo, info } from '../api/2fa'
import EmailVerify from '@/components/email/EmailVerify.vue'
import GoogleVerify from '@/components/google/GoogleVerify.vue'
import SmsVerify from '@/components/sms/SmsVerify.vue'

export default {
  name: 'verify.index',
  components: {
    EmailVerify,
    GoogleVerify,
    SmsVerify
  },
  data () {
    return {
      loading: false,
      email: '',
      def: '',
      redirect: '',
      tid: '',
      mode: 'verify',
      mobile: {
        code: '',
        number: ''
      }
    }
  },
  created () {
    if (this.$store.getters.mode !== this.mode) {
      this.$router.push({
        name: 'setting.index'
      })
      return false
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const retBindInfo = await bindInfo()
      if (!retBindInfo.data.status) {
        this.$router.push({
          name: 'customer.unused'
        })
        return false
      }
      for (const item in retBindInfo.data.binding) {
        if (retBindInfo.data.binding[item] === 1) {
          this.def = item.toLowerCase()
          break
        }
      }

      const retInfo = await info()
      this.email = retInfo.data.customer.email
      this.mobile = retInfo.data.customer.mobile
      this.redirect = retInfo.data.transaction.redirect
      this.tid = retInfo.data.transaction.tid
      this.loading = false
    }
  }
}
</script>
