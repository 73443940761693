<template>
  <div>
    <div class="code">
      <div class="input-box" v-for="(item, index) in form.code" :key="index">
        <b-form-input v-if="(index === 0)" v-model="item.val" :role="'code' + index" oninput="value=value.replace([/\D/g], '')" type="number" inputmode="numeric" min="0" max="9" pattern="[0-9]*" @input.native="inputVal($event, index)" @keydown.native="keydownVal(index)" @paste="onpaste(index)" @click="clickEvent(index)" autocomplete="off" autofocus />
        <b-form-input v-else v-model="item.val" :role="'code' + index" oninput="value=value.replace([/\D/g], '')" type="number" inputmode="numeric" min="0" max="9" pattern="[0-9]*" @input.native="inputVal($event, index)" @keydown.native="keydownVal(index)" @paste="onpaste(index)" @click="clickEvent(index)" autocomplete="off" />
      </div>
    </div>
    <b-modal id="code-modal" hide-footer hide-header no-close-on-backdrop no-close-on-esc centered>
      <div class="text-center">
        <div>
          <i class="owl-status-circle-info" style="font-size: 72px; color: #9CA3AF"></i>
        </div>
        <div class="mt-4" style="font-size: 20px; font-weight: 500">
          {{ $t('hint') }}
        </div>
        <div class="mt-3">
          {{ $t('error_code_count_content', { 'cnt': attempt, 'limit': limit }) }}
        </div>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-ghost btn-grey-dark" @click="closeEvent">{{ $t('close') }}</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'code.component',
  data () {
    return {
      toUp: false,
      toDown: true,
      form: {
        code: [
          { val: '' },
          { val: '' },
          { val: '' },
          { val: '' },
          { val: '' },
          { val: '' }
        ],
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: ''
      }
    }
  },
  props: {
    attempt: Number,
    limit: Number
  },
  methods: {
    clickEvent (index) {
      document.querySelector('[role="code' + index + '"]').select()
    },
    onpaste (index, val = null) {
      let code = []
      if (val) {
        code = val.split('')
      } else {
        const paste = (event.clipboardData || window.clipboardData).getData('text')
        code = paste.split('')
      }
      for (let i = 0; i < code.length; i++) {
        if (isNaN(code[i])) {
          alert('You should paste 0-9')
          document.querySelector('[role="code' + index + '"]').select()
          return false
        }
      }
      event.preventDefault()
      setTimeout(() => {
        for (let i = index; i < 6; i++) {
          if (code[i - index] === undefined) {
            continue
          }
          this.form.code[i].val = code[i - index]
        }
        if (this.form.code.map((x) => x.val).join('').length === 6) {
          this.$emit('auth')
        }
        let nextIndex = index + code.length
        nextIndex = nextIndex >= 5 ? 5 : nextIndex
        document.querySelector('[role="code' + nextIndex + '"]').select()
      }, 10)
    },
    keydownVal (index) {
      if (event.keyCode !== 8 && event.keyCode !== 46) {
        return false
      }
      this.form.code[index].val = ''
      const ctrl = this.getRole(event.target.getAttribute('role'), false)
      if (ctrl) {
        ctrl.select()
      }
    },
    inputVal (event, index) {
      if (event.target.value.length > 2) {
        this.onpaste(index, event.target.value)
        return false
      }

      if (event.target.value === '') {
        return false
      }

      const ctrl = this.getRole(event.target.getAttribute('role'), true)
      if (ctrl) {
        ctrl.select()
      }
      const code = this.form.code.map((x) => x.val).join('')
      if (code.length === 6) {
        if (!ctrl) {
          this.$emit('auth')
        }
        this.$emit('status', true)
      } else {
        this.$emit('status', false)
      }
    },
    getRole (curr, isNext = false) {
      let i = curr.substr(-1)
      if (isNext) {
        i++
      } else {
        i--
      }
      return document.querySelector('[role="code' + i + '"]')
    },
    closeEvent () {
      for (let i = 0; i < 6; i++) {
        this.form.code[i].val = ''
      }
      this.$bvModal.hide('code-modal')
      this.$emit('status', false)
      const ctrl = document.querySelector('[role="code0"]')
      setTimeout(function () {
        ctrl.select()
      }, 200)
    },
    reset () {
      const ctrl = document.querySelector('[role="code5"]')
      ctrl.select()
    },
    getCode () {
      return this.form.code.map((x) => x.val).join('')
    }
  }
}
</script>
