<template>
  <div v-if="type === def">
    <div class="card">
      <div class="title">
        <div class="caption">
          <div class="mr-3" style="display: inline-block; cursor:pointer" @click="redirectPage">
            <i class="owl-arrow-left"></i>
          </div>
          {{ $t('confirm_code_to_continue') }}
        </div>
      </div>
      <div class="content">
        <div style="color: #686E74">{{ $t('google.verify.content') }}</div>
        <div class="verificationBox mt-4">
          <div class="hint">
            <div style="display: inline-block">{{ $t('enter_code') }}</div>
            <div class="lost-code-box" @click="lost">
              {{ $t('lost_code') }}
            </div>
          </div>
          <div class="code">
            <CodeComponent ref="child" :attempt="attempt" :limit="limit" @auth="authEvent" @status="statusEvent" />
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="!status" class="btn btn-solid btn-blue" @click="auth">{{ $t('auth') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '../../api/2fa'
import CodeComponent from '@/components/CodeComponent.vue'
import lib from '@/lib'

export default {
  name: 'google.verify.component',
  props: {
    def: String,
    redirect: String,
    tid: String
  },
  components: {
    CodeComponent
  },
  data () {
    return {
      status: false,
      type: 'google',
      attempt: 0,
      limit: 0
    }
  },
  created () {
    if (this.type !== this.def) {
      return false
    }
  },
  methods: {
    async authEvent () {
      await this.auth()
    },
    statusEvent (status) {
      this.status = status
    },
    async auth () {
      this.status = false
      const code = this.$refs.child.getCode()
      const ret = await auth(this.type, { code: code })
      if (!ret.status) {
        this.attempt = ret.data.attempt
        this.limit = ret.data.limit
        if (this.limit === this.attempt) {
          this.$router.push({
            name: 'customer.unused'
          })
          return false
        }
        this.$bvModal.show('code-modal')
        this.status = true
        return false
      }
      const url = ret.data.redirect
      const tid = ret.data.tid
      const token = this.$store.getters.token
      this.$router.push({
        name: 'verify.success',
        params: {
          mode: true,
          url: url,
          token: token,
          tid: tid
        }
      })
    },
    lost () {
      this.$router.push({
        name: 'verify.google.reset',
        params: {
          mode: true
        }
      })
    },
    redirectPage () {
      const token = this.$store.getters.token
      const data = {
        token: token,
        tid: this.tid
      }

      this.$store.dispatch('removeJWT')
      this.$store.dispatch('removeMode')
      localStorage.clear()
      lib.post_to_url(this.redirect, data)
      return true
    }
  }
}
</script>
