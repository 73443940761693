const lib = {
  post_to_url (path, params, method = 'post') {
    const form = document.createElement('form')
    form.setAttribute('method', method)
    form.setAttribute('action', path)
    for (const key in params) {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', params[key])

      form.appendChild(hiddenField)
    }
    document.body.appendChild(form)
    form.submit()
  },
  setTimer (timer) {
    const minutes = 3
    if (localStorage.getItem('targetTime') == null) {
      timer.current = new Date()
      timer.target = new Date(timer.current.getTime() + (minutes * 60000))
      localStorage.setItem('targetTime', timer.target)
      timer.interval = null
      timer.status = true
    } else {
      timer.target = new Date(localStorage.getItem('targetTime'))
    }

    if (!timer.interval) {
      timer.interval = setInterval(() => {
        if (timer.current > timer.target) {
          clearInterval(timer.interval)
          localStorage.removeItem('targetTime')
          timer.status = true
        } else {
          timer.status = false
          timer.current = new Date()
          if (timer.target <= timer.current) {
            timer.sec = 0
          } else {
            timer.sec = Math.floor((timer.target - timer.current) / 1000)
          }
        }
      }, 100)
    }
  }
}

export default lib
